<style scoped>
.videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
}
.videos .head {
  display: flex;
  width: 100%;
  align-items: center;
}
.videosCard {
  width: 80%;
  margin-top: 16px;
}
/* .bilibili {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 75%;
}
.bilibili .iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
@media screen and (max-width: 600px) {
  .videosCard {
    width: 100%;
    margin-top: 16px;
  }
  .video-title {
    font-size: 10px;
  }
  .direct-to-bilibili {
    font-size: 8px;
  }
} */
</style>
<template>
  <div class="videos">
    <div class="head" inline>
      <span>课程：</span>
      <Select v-model="model1" style="width: 200px">
        <Option
          v-for="item in videosInfo"
          :value="item.title"
          :key="item.url"
          >{{ item.title }}</Option
        >
      </Select>
    </div>

    <Card class="videosCard" v-for="(item, index) in videosInfo" :key="index">
      <div class=""></div>
    </Card>

    <!-- <Card class="videosCard" v-for="(item, index) in videosInfo" :key="index">
      <a :href="item.url" target="_blank" slot="extra">
        <Button size="small" type="info">
          <Icon type="ios-videocam" />
          <span class="direct-to-bilibili">前往B站观看</span>
        </Button>
      </a>
      <div class="bilibili">
        <iframe
          width="560px"
          height="315px"
          class="iframe"
          :src="item.src"
          scrolling="no"
          border="0"
          frameborder="no"
          framespacing="0"
          allowfullscreen="true"
        ></iframe>
      </div>
      <p slot="title">
        <span class="video-title">{{ item.title }}</span>
      </p>
    </Card> -->
    <!-- <Col span="11">
      <Card dis-hover>
        <p slot="title">Disable card with hover shadows</p>
        <p>Content of card</p>
        <p>Content of card</p>
        <p>Content of card</p>
      </Card>
    </Col> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      videosInfo: [],
      model1: ""
    };
  },
  mounted: function () {
    const videosList = [
      {
        title: "尚学FIT9131预习课第一周",
        src: "//player.bilibili.com/player.html?aid=88764391&cid=151638190&page=1",
        url: "https://www.bilibili.com/video/av88764391"
      },
      {
        title: "尚学提前预习课FIT9131第二周练习课",
        src: "//player.bilibili.com/player.html?aid=90516288&cid=154575447&page=1",
        url: "https://www.bilibili.com/video/av90516288"
      },
      {
        title: "尚学FIT9131提前预习课第三周",
        src: "//player.bilibili.com/player.html?aid=92406772&cid=157779454&page=1",
        url: "https://www.bilibili.com/video/av92406772"
      },
      {
        title: "尚学FIT9132预习课第一周",
        src: "//player.bilibili.com/player.html?aid=90091416&cid=153867190&page=1",
        url: "https://www.bilibili.com/video/av90091416"
      },
      {
        title: "尚学FIT9132预习课第二周",
        src: "//player.bilibili.com/player.html?aid=92448662&cid=157850564&page=1",
        url: "https://www.bilibili.com/video/av92448662"
      },
      {
        title: "尚学提前预习课FIT9137第一周",
        src: "//player.bilibili.com/player.html?aid=90104908&cid=153867819&page=1",
        url: "https://www.bilibili.com/video/av90104908"
      },
      {
        title: "尚学提前预习课FIT5195",
        src: "//player.bilibili.com/player.html?aid=90781193&cid=154853244&page=1",
        url: "https://www.bilibili.com/video/av90781193"
      }
    ];
    //  每两个为一个小数组  array里面一共有bin这么多个小数组
    // const bin = Math.ceil(videosList.length / 2);
    //   把数组里面  变成 bin这个多个小数组
    //   videosInfo
    // for (let index = 0; index < videosList.length; index++) {
    //   const element = videosList[index];
    //   if(index % 2 === 0) {
    //     //  每两个元素   往videosInfo里面push一个空的新的小数组
    //     this.videosInfo.push([])
    //   }
    //   //  子数组的index
    //   const binIndex = Math.floor( index / 2);
    //   //   把对应的元素push到子数组里面
    //   this.videosInfo[binIndex].push(element);
    // }
    this.videosInfo = videosList;
  }
};
</script>
